<template>
  <b-modal id="article-price-modal" size="lg" title="Add Article Price" v-model="showModal">
    <b-overlay :show="loading" rounded="sm">
      <p>Adding price for:
        <strong>{{
            selectedArticle ? (selectedArticle.latina_article_id + ' - ' + selectedArticle.title) : ''
          }}</strong>. Fields marked with (<span class="text-danger">*</span>) are mandatory.
      </p>

      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="supplier-select">Select Supplier <span class="text-danger">*</span></label>
          </div>
          <div class="formElement__input">
            <v-select name="supplier-select" id="supplier-select" taggable :placeholder="`Please select`"
                      :create-option="supplierName => ({ name: supplierName, id: 0})"
                      v-model="supplier" label="name"
                      :options="suppliers"></v-select>
          </div>
        </div>
      </div>
      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="currency-select">Currency <span class="text-danger">*</span></label>
          </div>
          <div class="formElement__input">
            <v-select name="currency-select" id="currency-select" :placeholder="`Please select`"
                      v-model="price.currency"
                      :options="['EUR', 'GBP', 'USD', 'CAD', 'CHF']"></v-select>
          </div>
        </div>
        <div class="formElement">
          <div class="formElement__label">
            <label for="price-date">Price date <span class="text-danger">*</span></label>
          </div>
          <div class="formElement__input">
            <datepicker placeholder="Select Date" v-model="price.date"></datepicker>
          </div>
        </div>
      </div>
      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="article-price-unit">Price (Unit) <span class="text-danger">*</span></label>
          </div>
          <div class="formElement__input">
            <b-form-input name="article-price-unit" v-model="price.unit_price" type="number" placeholder="0.00"/>
          </div>
        </div>
        <div class="formElement">
          <div class="formElement__label">
            <label for="article-price-case">Price (Case)</label>
          </div>
          <div class="formElement__input">
            <b-form-input name="article-price-case" v-model="price.case_price" @input="calculateUnitPrice" type="number"
                          placeholder="0.00"/>
          </div>
        </div>
      </div>
      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="article-stock-bottles">Stock (Bottles)</label>
          </div>
          <div class="formElement__input">
            <b-form-input name="article-stock" v-model="price.stock_amount_bottles" type="number" placeholder="0"/>
          </div>
        </div>

        <div class="formElement">
          <div class="formElement__label">
            <label for="article-stock-bottles">Stock (Cases)</label>
          </div>
          <div class="formElement__input">
            <b-form-input name="article-stock" v-model="price.stock_amount_cases" type="number" placeholder="0"/>
          </div>
        </div>
      </div>

      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="article-price-unit">Supplier Ref</label>
          </div>
          <div class="formElement__input">
            <b-form-input name="article-supplier-ref" v-model="price.supplier_ref" type="text" placeholder="Optional"/>
          </div>
        </div>
        <div class="formElement">
          <div class="formElement__label">
            <label for="article-price-unit">Expiry</label>
          </div>
          <div class="formElement__input">
            <v-select name="expiry-select" id="expiry-select" :placeholder="`Please select`"
                      v-model="price.expiry"
                      :options="['Supplier Default', '1 day', '2 days', '1 week', '2 weeks', '3 weeks', '1 month', '2 months', '3 months', '4 months', '5 months', '6 months',
                      '7 months', '8 months', '9 months', '10 months', '11 months', '12 months']"></v-select>
          </div>

        </div>
      </div>

      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="price-comment">Comment</label>
          </div>
          <div class="formElement__input fullwidth">
            <b-form-input name="price-comment" v-model="price.comment" type="text" placeholder="Optional"/>
          </div>
        </div>
        <div class="formElement">
          <div class="formElement__label">
            <label for="pricelist-type">Price Type <span class="text-danger">*</span></label>
          </div>
          <div class="formElement__input">
            <v-select name="pricelist-type" id="pricelist-type" :placeholder="`Please select`"
                      v-model="price.price_type"
                      :reduce="option => option.value"
                      :options="[
                             {
                        label: 'Please Select',
                        value: ''
                      },
                      {
                        label: 'Monthly/weekly',
                        value: 'regular'
                      },
                      {
                        label: 'Annual',
                        value: 'annual'
                      },
                      {
                        label: 'Spot Offer',
                        value: 'spot'
                      }]"></v-select>
          </div>
        </div>
      </div>

      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="ean-case">Products Type (T1/T2) <span class="text-danger">*</span></label>
          </div>
          <div class="formElement__input">
            <v-select name="honors-paper-trail-select" id="honors_paper_trail_select" :placeholder="`Please select`"
                      class="wide"
                      v-model="price.price_product_type"
                      :reduce="option => option.value"
                      :options="[
                          {
                            label: 'Please Select',
                            value: null
                          },
                          {
                            label: 'T1',
                            value: 'T1'
                          },
                          {
                            label: 'T2',
                            value: 'T2'
                          }]">

            </v-select>
          </div>
        </div>
      </div>

    </b-overlay>
    <template #modal-footer>

      <b-button variant="success" @click="addPrice">
        Add Price
      </b-button>
      <b-button variant="light" @click="cancelUpload">
        Cancel
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {mapState} from "vuex";

export default {
  name: "AddArticlePriceModal",
  data() {
    return {
      loading: false,
      supplier: false,
      price: {
        currency: 'EUR',
        date: new Date(),
        unit_price: null,
        case_price: null,
        supplier_ref: '',
        stock_amount_bottles: null,
        stock_amount_cases: null,
        comment: null,
        expiry: 'Supplier Default',
        price_type: 'spot',
        price_product_type: null
      },
    }
  },
  components: {
    Datepicker
  },
  watch: {
    supplier: function (newSupplier) {
      if (!newSupplier) {
        return;
      }

      const supplierProductType = newSupplier.default_product_type;

      if (supplierProductType && ['T1','T2'].includes(supplierProductType)) {
        this.price.price_product_type = newSupplier.default_product_type;
      }
    }
  },
  computed: {
    showModal: {
      get() {
        return this.$store.state.articles.modals.isPriceModalOpen
      },
      set(v) {
        if (v) {
          this.$store.dispatch('articles/openPriceModal');
        } else {
          this.$store.dispatch('articles/closePriceModal');
        }
      }
    },
    ...mapState({
      suppliers: state => state.ancillaries.suppliers,
      selectedArticle: state => state.articles.currentArticle
    })
  },
  methods: {
    addPrice() {
      if (this.validate() !== true) {
        this.$toast.error('Please fill in all required fields correctly');
        return;
      }

      this.loading = true;
      this.$store.dispatch('articles/addArticlePrice', {
        article_id: this.selectedArticle.id,
        supplier_id: this.supplier.id,
        supplier_name: this.supplier.name,
        currency: this.price.currency,
        price_date: this.formatDate(this.price.date),
        unit_price: this.price.unit_price,
        case_price: this.price.case_price,
        supplier_ref: this.price.supplier_ref,
        stock_amount_bottles: this.price.stock_amount_bottles,
        stock_amount_cases: this.price.stock_amount_cases,
        expiry: this.price.expiry,
        price_type: this.price.price_type,
        comment: this.price.comment,
        price_product_type: this.price.price_product_type

      }).then(() => {
        this.$store.dispatch('articles/closePriceModal');
        this.$store.dispatch('articles/triggerRefresh');
        this.reset();
      }, (error) => {
        this.$toast('Something went wrong! ' + error.toString());
      });
    },
    calculateUnitPrice(newPrice) {
      let unitPrice = newPrice / this.selectedArticle.content_count;
      this.price.unit_price = unitPrice.toFixed(2);
    },
    validate() {
      if (!this.supplier.name) {
        return false;
      }

      if (!this.price.currency) {
        return false;
      }
      if (!this.price.date) {
        return false;
      }
      if (!this.price.unit_price) {
        return false;
      }

      if (!this.price.price_product_type) {
        return false;
      }

      return true;

    },
    cancelUpload() {
      this.loading = false;
      this.$store.dispatch('articles/closePriceModal');
    },
    reset() {
      this.price.unit_price = null;
      this.price.case_price = null;
      this.price.supplier_ref = null;
      this.price.stock_amount = null;
      this.loading = false;
    }
  }

}
</script>


<style lang="scss">
</style>
